// TODO: This is a performance, lazy-load target. Need to get dynamic imports() working first
require('public/js/polyfill/intersection-observer');

const INTERSECTION_RATIO_MIN = 0;
const LAZY_LOAD_SRC_ATTRIBUTE = 'lazySrc';
const LAZY_LOAD_ELEMENT_CLASS = 'lazy-load';
const LAZY_LOAD_ELEMENT_SELECTOR = `.${LAZY_LOAD_ELEMENT_CLASS}`;
const LAZY_LOAD_ELEMENT_LOADED_CLASS = 'loaded';

let observer;

/**
 * @returns {IntersectionObserverInit}
 */
const getObserverConfig = () => {
	return {
		threshold: 0.01,
		rootMargin: '0px',
	};
};

/**
 * @description Skips waiting on intersection and loads given images
 * @function
 * @param {HTMLImageElement[]} images
 * @returns {void}
 */
const loadImagesNow = (images) => {
	const imagesCount = images.length;
	for (let i = 0; i < imagesCount; i++) {
		const element = images[i];
		doLazyLoad(element);
	}
};

/**
 * @function
 * @param {unobserve} unobserve
 * @returns {(intersects: IntersectionObserverEntry[]) => void}
 */
const handleIntersection = (unobserve) => {
	return (intersects) => {
		const intersectsCount = intersects.length;
		for (let i=0; i < intersectsCount; i++) {
			const item = intersects[i];
			if (item.intersectionRatio > INTERSECTION_RATIO_MIN) {
				const element = item.target;
				unobserve(element);
				doLazyLoad(element);
			}
		}
	};
};

/**
 * @description Lets load the image!
 * @param {HTMLElement} element
 * @returns {void}
 */
const doLazyLoad = (element) => {
	//If image has already been loaded do not load it again.
	// This prevents duplicate images load when firing the lazyLoad.attach() method multiple times.
	if(element.classList.contains(LAZY_LOAD_ELEMENT_LOADED_CLASS)) {
		return;
	}

	if (element.tagName === 'IMG' && element.dataset.src) {
		loadImageFromDataset(element);
	} else if (element.tagName === 'IMG') {
		loadImage(element);
	} else if (element.getAttribute(LAZY_LOAD_SRC_ATTRIBUTE)) {
		loadBackgroundImage(element);
	} else {
		loadCustom(element);
	}
};

/**
 * @description Remove Lazy Load Classes from arbitrary HTML Element
 * @param {HTMLElement} element
 * @returns {void}
 */
const loadCustom = (element) => {
	element.classList.remove(LAZY_LOAD_ELEMENT_CLASS);
	element.classList.add(LAZY_LOAD_ELEMENT_LOADED_CLASS);
	if(typeof element.onLazyLoadComplete === 'function') {
		element.onLazyLoadComplete(element, element.parentNode);
	}
};

/**
 * @description Lazy Load a background Image for an arbitrary HTML Element
 * @param {HTMLElement} element
 * @returns {void}
 */
const loadBackgroundImage = (element) => {
	const src = element.getAttribute(LAZY_LOAD_SRC_ATTRIBUTE);
	element.style.backgroundImage = `url('${src}')`;
	element.classList.remove(LAZY_LOAD_ELEMENT_CLASS);
	element.classList.add(LAZY_LOAD_ELEMENT_LOADED_CLASS);
	if(typeof element.onLazyLoadComplete === 'function') {
		element.onLazyLoadComplete(element, element.parentNode);
	}
};

/**
 * @description Lets load the image!
 * @param {HTMLImageElement} element
 * @returns {void}
 */
const loadImage = (element) => {
	const imageParent = element.parentNode;
	const image = element.cloneNode();
	const src = element.getAttribute(LAZY_LOAD_SRC_ATTRIBUTE);

	image.src = src;
	image.alt = element.getAttribute('alt');
	image.classList.add('full-res');
	image.classList.remove('placeholder');

	// Remove the lazySrc attribute
	image.removeAttribute(LAZY_LOAD_SRC_ATTRIBUTE);
	imageParent.insertBefore(image, element);

	// ADA: Update alt of placeholder image so that there are not adjacent duplicates
	const alt = element.getAttribute('alt');
	if (alt) {
		element.setAttribute('alt', `${alt} Placeholder`);
	}

	// Lets create a new image, add to DOM, then apply CSS to cross-fade
	image.onload = () => {
		imageParent.classList.add('flip');
		if(typeof element.onLazyLoadComplete === 'function') {
			element.onLazyLoadComplete(image, imageParent);
		}
	};

	// Add a loaded class to the cloned and original elements
	// We do this just so we can know not to reload the images
	image.classList.add('loaded');
	element.classList.add('loaded');
};

/**
 * @description A simplified lazy-load method that will not create multiple dom elements
 * @param {HTMLImageElement} img
 * @returns {void}
 */
const loadImageFromDataset = (img) => {
	img.onerror = () => {
		if (img.dataset.altSrc) {
			img.src = img.dataset.altSrc;
			img.dataset.altSrc = '';
		}
	};
	img.src = img.dataset.src;
};

/**
 * @description Attach IntersectionObserver to given elements
 * @param {HTMLElement[]} imageElements
 * @returns {void}
 */
const attachObserver = (imageElements, onLoadCallback) => {
	const elements = imageElements || document.querySelectorAll(LAZY_LOAD_ELEMENT_SELECTOR);
	const imageCount = elements.length;
	if (!('IntersectionObserver' in window)) {
		loadImagesNow(elements);
	} else {
		if (!observer) {
			observer = new IntersectionObserver(handleIntersection(unobserve), getObserverConfig());
		}

		for (let i=0; i < imageCount; i++) {
			const item = elements[i];
			if(typeof onLoadCallback === 'function') {
				item.onLazyLoadComplete = onLoadCallback;
			}
			// Prevent attaching multiple observers
			unobserve(item);
			// Finally, observe element for viewport intersection
			observe(item);
		}
	}
};

/**
 * @description Call to stop observing a DOM element
 * @function
 * @param {HTMLElement} element
 * @returns {void}
 */
const unobserve = (element) => {
	if (observer) {
		observer.unobserve(element);
	}
};

/**
 * @description Call to start observing a DOM element
 * @param {HTMLElement} element
 * @returns {void}
 */
const observe = (element) => {
	if (observer) {
		observer.observe(element);
	}
};

/**
 * @description Call to stop observing a DOM elements
 * @param {HTMLElement[]} elements
 * @returns {void}
 */
const unobserveAll = (elements) => {
	if (elements && elements.length) {
		for (let i=0; i < elements.length; i++) {
			unobserve(elements[i]);
		}
	} else {
		unobserve(elements);
	}
};

module.exports = () => {
	return Object.freeze({
		getSelector() { return LAZY_LOAD_ELEMENT_SELECTOR; },
		attach: attachObserver,
		detatch: unobserveAll,
	});
};
